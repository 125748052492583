var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4","lg":"4","xl":"4"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('div',{staticClass:"mr-5"},[_c('h3',[_vm._v("Prezence")])])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2","lg":"2","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.memberStatusItems,"dense":"","outlined":"","item-text":"text","item-value":"value"},model:{value:(_vm.memberStatus),callback:function ($$v) {_vm.memberStatus=$$v},expression:"memberStatus"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3","lg":"3","xl":"3"}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"mr-2 mt-5",attrs:{"label":"search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.AppUsersData,"items":_vm.AppUsersItems,"dense":"","fixed-header":_vm.fixed,"height":_vm.height,"search":_vm.search,"loading":_vm.isLoading,"items-per-page":10},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No App Users available ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.user_activated_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.get_date(item.user_activated_on)))])]}},{key:"item.admin_app_version",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-n2 mr-2",attrs:{"medium":"","color":item.admin_device_type == 'ANDROID' ? '#a4c639' : '#555555'},domProps:{"textContent":_vm._s(
              item.admin_device_type == 'ANDROID'
                ? 'mdi-android'
                : item.admin_device_type == 'IOS'
                ? 'mdi-apple'
                : '-'
            )}}),_c('span',[_vm._v(_vm._s(item.admin_app_version)+" ")])]}},_vm._l((_vm.AppUsersData),function(h,i){return {key:("header." + (h.value)),fn:function(ref){
            var header = ref.header;
return [_c('div',{key:i},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[(header.tooltip_text != undefined)?_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(header.tooltip_text))]):_vm._e()])],1)]}}}),{key:"item.user_name",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.user_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.user_name))])])]}},{key:"item.organisation_name",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.organisation_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.organisation_name))])])]}},{key:"item.admin_app_last_login",fn:function(ref){
            var item = ref.item;
return [(item.admin_app_last_login != undefined)?_c('span',[_vm._v(_vm._s(_vm.get_date2(item.admin_app_last_login)))]):_c('span',[_vm._v("-")])]}}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }